@font-face {
  font-family: 'BIZ UDPGothic';
  src:
    url('assets/font/BIZ-UDPGothic/BIZUDPGothic-Regular.woff2') format('woff2'),
    url('assets/font/BIZ-UDPGothic/BIZUDPGothic-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BIZ UDPGothic';
  src:
    url('assets/font/BIZ-UDPGothic/BIZUDPGothic-Bold.woff2') format('woff2'),
    url('assets/font/BIZ-UDPGothic/BIZUDPGothic-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  src: url(assets/font/Montserrat-VariableFont_wght.ttf);
  font-display: swap;
}

@font-face {
  font-family: MsGothic;
  src: url(assets/font/msgothic.ttc);
  font-display: swap;
}

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  /* font-family: inherit, MsGothic; */
  font-family: MsGothic;
  /* This padding will be 0 on browsers */
  padding-top: env(safe-area-inset-top);
}

#root,
.App {
  height: 100svh;
}
/**
 * TODO:
 * This is the fastest way to fix non responsiveness for mobile phones.
 * This applies to iPad all the way to iPad Mini.
 * Please reimplement if have more time
 */
@media only screen and (min-width: 768px) {
  body {
    overflow: hidden;
  }
}

body::after {
  content: '';
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

div.appTitle-container#appTitle-container {
  padding-top: 40px;
}

.appTitle-container h1.appTitle {
  margin-top: 0px;
  margin-bottom: 20px;
}

h1.appTitle,
h3.appTitle {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  color: #033653;
}

button.big-btn,
button.btn-sign,
button.btn-sign:hover,
button.big-btn:hover {
  max-width: 472px;
  width: 100%;
  text-transform: capitalize;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  border-radius: 4px;
  color: var(--ds-blue-dark, #0e61fe);
  border: 2px solid var(--ds-blue-dark, #0e61fe);
  background: var(--ds-gray-light, #e9ecf3);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.15);
  padding-top: 17px;
  padding-bottom: 17px;
}

button.btn-sign > span.MuiButton-startIcon,
button.big-btn > span.MuiButton-startIcon {
  margin-right: 30px;
}

button.big-btn > span.MuiButton-startIcon svg {
  font-size: 35px;
}

.btn-sign-text {
  display: flex;
  flex: 1 1 auto;
  color: #0e61fe;
  font-weight: 600;
  font-size: 20px;
  text-decoration: underline;
  text-transform: capitalize;
}

.btn-sign-text-reuse {
  display: flex;
  flex: 1 1 auto;
  color: #45454d;
  font-weight: 600;
  font-size: 20px;
  text-decoration: underline;
  text-transform: capitalize;
}

.box-settings {
  max-width: 472px;
  margin-top: 32px;
  font-family: MsGothic;
}

.box-settings h2 {
  color: var(--ds-black-2, #033653);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.box-settings h3 {
  text-align: left;
  color: var(--ds-black, #0e0e20);
  font-family: MsGothic;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.box-settings > div.MuiCardContent-root {
  padding: 16px 54px 32px;
}

div.note-container#note-container {
  margin: 2.5em 0;
}

.note-settings {
  width: 472px;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 1em;
}

.note-settings > .icon-container {
  display: flex;
  gap: 0.5em;
}

.note-settings > .icon-container span {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: #033653;
}

.note-settings > .note-text {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 128%;
  color: #000;
}

div.input-form-control {
  width: 140px;
  display: flex;
}

div.input-form-control div.MuiInputBase-root.MuiOutlinedInput-root {
  background: var(--ds-gray-light, #e9ecf3);
}

div.input-form-control div.MuiInputBase-root.MuiOutlinedInput-root > input {
  background: #ffffff;
}

p.form-label {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.3px;
  color: #000000;
  text-align: left;
  position: relative;
  top: -10px;
}

div.radio-group-btn label span.MuiTypography-root {
  font-family: MsGothic;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.3px;
  color: var(--ds-black, #0e0e20);
}

div.face-type-box {
  text-align: left;
  margin-bottom: 40px;
}

label.upload-video-btn,
label.upload-video-btn:hover {
  max-width: 469px;
  width: 100%;
  text-transform: capitalize;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  border-radius: 4px;
  color: var(--ds-blue-dark, #036eb8);
  border: 2px solid var(--ds-blue-dark, #036eb8);
  background: var(--ds-gray-light, #ffffff);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.15);
  padding-top: 17px;
  padding-bottom: 17px;
  height: 70px;
}

label.upload-video-btn span {
  color: #033653;
}

div.note-row-video {
  text-align: left;
  margin-left: 250px;
  margin-right: 250px;
  margin-top: 10px;
  padding: 0px;
  background: #64b5f669;
  border-radius: 8px;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 10px;
}

div.note-row-video p {
  text-align: justify;
  font-family: MsGothic;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 128%;
}

div.note-row {
  text-align: left;
  margin: 60px;
}
div.note-row p {
  text-align: justify;
  font-family: MsGothic;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 128%;
}

div.note-row-video button.close-btn-note svg {
  top: 0px;
}

div.note-row-video button.close-btn-note {
  top: -12px;
  right: -15px;
}

div.note-row svg,
div.note-row-video svg {
  position: relative;
  top: 6px;
  color: #0e61fe;
}

button.play-again-btn {
  color: var(--ds-black-2, #033653);
  font-family: MsGothic;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  padding: 15px;
  height: auto;
  text-transform: capitalize;
}

a.download-url-btn {
  border-radius: 8px;
  background: var(--ds-blue-dark, #0e61fe);
  padding: 11px;
  height: auto;
  font-family: MsGothic;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-transform: capitalize;
  width: 320px;
  height: 56px;
}

button.clearSig {
  color: var(--ds-black-2, #033653);
  text-align: right;
  font-family: MsGothic;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-decoration-line: underline;
}
h2.signBelow {
  color: var(--ds-black-2, #033653);
  text-align: center;
  font-family: MsGothic;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.3px;
}

div.agreement-info {
  padding: 0 4em;
  flex-basis: auto;
}

#child-modal-title {
  text-align: center;
}

div.agreement-body#agreement-body {
  overflow: auto;
  height: 285px;
  padding: 0 4em;
  flex-basis: auto;
  padding-left: 25px;
  padding-right: 0px;
  text-align: justify;
}

div.container-sig {
  background: var(--ds-gray-light, #e9ecf3);
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 40px;
  padding-left: 40px;
}

a.file_link_download button.pdf-btn {
  color: var(--ds-white, #fff);
  text-align: center;
  font-family: MsGothic;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-transform: math-auto;
}

label.default-btn span.MuiTypography-body1 {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.3px;
  color: #000000;
  font-family: MsGothic;
}

div.signature-form {
  background: var(--DS-gray-light, #e9ecf3);
}

div.signature-container {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 4em 0;
}

.signature-form-textfieldContainer {
  padding: 3em 4em 0;
}

.signature-form-textfield {
  background: #fff;
}

.signature-form-textfield input::placeholder {
  text-align: center;
}

div.signature-canvas {
  background: #fff;
  margin: 0 4em 0;
  margin-top: 50px;
}

.signature-canvas canvas {
  width: 100%;
}

.download-pdf-container#download-pdf-container {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  margin-top: 10px;
}
.download-pdf-container button {
  height: 56px;
  width: 100%;
  max-width: 385px;
}

div.actions {
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 108px;
  gap: 2em;
}

div.close-action {
  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1em;
  float: left;
  cursor: pointer;
}

div.close-action svg {
  width: 55px;
  position: relative;
}

div.download-action {
  height: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1em;
  float: right;
}

div.download-action button.download-btn-action svg {
  width: 75px;
}

div.download-action button svg {
  width: unset;
}

label.reuse-video-btn {
  font-family: MsGothic;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  max-width: 364px;
  height: 56px;
  width: 100%;
  border-radius: 8px;
  background: var(--ds-blue-dark, #0e61fe);
}

.icon-btn {
  float: right;
}

div.advance_settings {
  margin-bottom: 20px;
  font-weight: 700;
  box-shadow: unset;
}

div.mosaic_settings {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  display: none;
}

.css-o4b71y-MuiAccordionSummary-content {
  color: #626569;
  font-family: MsGothic;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  flex-grow: 5;
}

.css-i4bv87-MuiSvgIcon-root {
  font-size: 35px;
}

/* overwrite default css */
.css-yw020d-MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: none !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-1vm0r4s-MuiPaper-root {
  width: 95%;
}
.css-yz9k0d-MuiInputBase-input {
  text-align: center;
}

/* overwrite default css */
.css-1bxg8jb-MuiButtonBase-root-MuiIconButton-root {
  color: #0e0e20 !important;
}

div.grid-container#grid-container {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  padding-top: 16px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 24px;
  background-color: #fff;
  border: 2px solid #000;
  box-shadow:
    0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  height: 700px;
  width: 85%;
  overflow: auto;
}

span.video-uploader-input div.ant-upload.ant-upload-select {
  padding: 185px;
  padding-left: 200px;
  padding-right: 200px;
  margin: 0px;
  background: #ffffff;
  border: 2px dashed #1677ff;
}

span.video-uploader-input
  div.ant-upload.ant-upload-select
  span.ant-upload
  button {
  background: #ffffff;
  padding: 147px;
  padding-left: 169px;
  padding-right: 169px;
  border-radius: 5px;
}

div.MuiGrid-root.MuiGrid-item.company-container {
  margin-left: 0px;
}

ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
  display: flex;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 10px;
  padding-left: 10px;
}

/* overwrite default css */
.css-cuggrr-MuiLoadingButton-loadingIndicator {
  left: auto !important;
}

div.header-preview-container {
  position: fixed;
  background-color: #ffffff;
  border-bottom: 1px solid #8b949f;
}

div.actions {
  position: fixed;
  width: 100%;
  padding-right: 40px;
  bottom: 0px;
  height: 25%;
}

div.video-setting-container {
  bottom: 195px;
  right: 0;
  left: 0;
}
/**
 * TODO:
 * This is the fastest way to fix non responsiveness for mobile phones.
 * This applies to iPad all the way to iPad Mini.
 * Please reimplement if have more time
 */
@media only screen and (min-width: 768px) {
  div.video-setting-container {
    position: fixed;
  }
}

.ant-popover {
  width: 98%;
}

button.configure-btn,
button.configure-btn:hover,
a.configure-btn,
a.configure-btn:hover {
}

button.configure-onload-btn div,
button.configure-btn div {
  display: flex;
}

button.configure-onload-btn,
button.configure-onload-btn:hover {
  border-color: #8b949f;
  background: #8b949f;
  color: #ffffff;
  font-size: 24px;
  font-weight: 400;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  height: 45px;
  pointer-events: none;
}

div.canvas-container {
  background-color: #ffffff;
}

div.ant-popover-content div.ant-popover-inner {
  margin-right: 10px;
  margin-left: 10px;
}

/**
 * TODO:
 * This is the fastest way to fix non responsiveness for mobile phones.
 * This applies to iPad all the way to iPad Mini.
 * Please reimplement if have more time
 */
@media only screen and (min-width: 768px) {
  div.actions-container {
    position: fixed;
  }
}

div.actions-container .css-mhc70k-MuiGrid-root > .MuiGrid-item {
  padding-left: 25px;
  padding-right: 15px;
}

#alert-dialog-content {
  padding-bottom: 0px;
}

#alert-dialog-description {
  color: #000;
  font-family: MsGothic;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  padding: 25px;
  padding-bottom: 0px;
}

.text-center {
  text-align: center;
}

div#header-modal-container {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  background: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  padding: 25px;
  padding-bottom: 10px;
  z-index: 10;
}

div.icon-modal-header svg {
  position: relative;
  width: 50px;
  height: 45px;
  bottom: 10px;
}

div#header-modal-container div.modal-header-title.MuiGrid-root.MuiGrid-item {
  color: #000;
  font-family: MsGothic;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  padding-left: 50px;
}

.text-right {
  text-align: right;
}

div.company-container p {
  font-family: 'BIZ UDPGothic';
  color: #525664;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding-right: 0px;
  padding-top: 20px;
  line-height: 120%;
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  div.company-container p {
    font-size: 14px;
  }
}

p.numbered.terms-sub {
  margin: 0px;
  padding: 0px;
  margin-right: 50px;
}

p.numbered.first.terms-sub {
  margin-top: 35px;
}

#terms p.established,
#title-privacy p.established {
  text-align: right;
  padding: 0px;
  padding-top: 35px;
}

div.terms li {
  list-style-type: none;
}

div.terms#terms * {
  && {
    line-height: 142%;
  }
}

div.terms#terms {
  max-width: 688px;
  && {
    margin-top: 0px;
    padding-bottom: 160px;
  }
  @media only screen and (max-width: 600px) {
    && {
      padding-bottom: 104px;
    }
  }
  padding-top: 0px;
  padding-left: 0px;
}

div.company-container div.terms#terms p {
  padding-left: 45px;
  padding-right: 0px;
}

div#title-privacy p.title-privacy {
  font-family: 'BIZ UDPGothic';
  color: #525664;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.3px;
}

div#title-privacy * {
    && {
        line-height: 142%;
    }
}
div#title-privacy p.sub {
  padding: 0px;
  margin-right: 0px;
}

div#title-privacy p.sub-title {
  padding: 0px;
  margin-left: 60px;
  margin-bottom: 0px;
  margin-top: 0px;
}

div.company-container p.title {
  padding-bottom: 0px;
  margin-bottom: -20px;
  padding-top: 35px;
}

div.company-container li.title {
  padding-bottom: 0px;
  margin-bottom: -20px;
  padding-top: 35px;
  font-family: 'BIZ UDPGothic';
  color: #525664;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.3px;
}

div.company-container li.title:first-child {
  padding-top: 0px;
}

div.company-container p.title-privacy:first-child {
  padding-top: 0px;
  margin-top: 0px;
}

div.terms p.subTitle.sub-words {
  padding-top: 0px;
  padding-left: 30px;
  margin: 0px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 55px;
}

h3.appTitle {
  margin-top: 0px;
}

ul.how-to-use {
  list-style-type: none;
}

ul.how-to-use li.title svg {
  position: relative;
  top: 15px;
  padding-right: 10px;
}

img.how-to-use-image {
  max-width: 335px;
  width: auto;
  margin-top: 75px;
  margin-left: 20px;
}

div.grid-container {
  flex-wrap: nowrap;
}

ul.how-to-use div p:first-child {
  padding-top: 120px;
}

ul.how-to-use div p.orange {
  color: #f24931;
}

ul.how-to-use div p.subSpace {
  padding-top: 155px;
}

ul.how-to-use div p.subSpaceTwo {
  padding-top: 220px;
}

ul.how-to-use div p.super-min {
  padding-top: 1px;
}

ul.how-to-use div p.super-max {
  padding-top: 20px;
}

ul.how-to-use div p.min-top {
  padding-top: 75px;
}

ul.how-to-use div p.max-top-pad {
  padding-top: 180px;
}

ul.how-to-use div p {
  margin-top: 5px;
  margin: 0px;
  padding: 0px;
  text-align: left;
  padding-right: 25px;
  padding-left: 60px;
  padding-top: 30px;
}

ul.how-to-use div {
  text-align: center;
}

ul.sub-lists li p {
  margin: 0px;
}

ul.sub-lists {
  margin-left: 35px;
}

div#title-privacy p.sub-title.m-left {
  margin-left: 75px;
}

p.sub.orgo-url {
  margin-top: 0px;
  margin-bottom: 0px;
}
p.sub.last-sub {
  margin-left: 55px;
}
div.terms#terms p.terms-sub {
  && {
    padding-left: 25px;
  }
}

div.company-container li.title.last-item {
  margin-right: 35px;
  margin-left: 15px;
  font-size: 16px;
}

div.ant-popover-inner-content {
  text-align: center;
}

div.ant-popover-inner-content div.radio-group-btn {
  justify-content: center;
}

.radio-group-btn .MuiRadio-root span {
  box-sizing: content-box;
}

/* !important here are used to override the css on a normal resolution */
@media only screen and (max-width: 1440px) {
  h3.appTitle {
    margin-bottom: 15px;
  }

  div.appTitle-container#appTitle-container {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 768px) {
  ul.how-to-use div p {
    padding-right: 10px;
    padding-left: 20px;
  }
}

@media only screen and (max-width: 550px) {
  div.appTitle-container#appTitle-container {
    padding-top: 20px;
  }

  h3.appTitle svg {
    width: 80%;
  }

  h3.appTitle {
    margin-bottom: 10px;
  }

  label.upload-video-btn,
  label.upload-video-btn:hover {
    width: 82%;
    height: 55px;
  }

  label.upload-video-btn {
    width: 82%;
    height: 55px;
  }

  div#header-modal-container div.modal-header-title {
    font-size: 18px !important;
    padding-top: 22px;
    padding-left: 20px !important;
  }

  ul#how-to-use-mobile li.title p {
    padding: 0px;
    margin-bottom: 0px;
    margin-left: 55px;
    margin-top: -30px;
    margin-right: 120px;
  }

  ul#how-to-use-mobile li.title {
    padding-top: 0px;
  }

  div.company-container li.title.last-item {
    width: auto;
    margin: 0px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 35px !important;
    padding-bottom: 50px;
    font-size: 14px;
  }

  ul#how-to-use-mobile {
    padding-left: 0px;
  }

  ul.how-to-use div {
    justify-content: center;
    width: 100%;
  }

  img.how-to-use-image {
    margin-left: 0px;
    max-width: 485px;
    margin-top: 40px;
    width: 100%;
  }

  ul.how-to-use div p:first-child {
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
  }

  ul.how-to-use div p.orange {
    color: #f24931;
    padding-top: 25px;
    padding-left: 10px;
    padding-right: 10px;
  }

  ul.how-to-use div p:nth-child(3) {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }

  ul.how-to-use div p.subSpaceThree {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    width: auto;
  }

  ul#how-to-use-mobile li.title.m-top {
    padding-top: 20px;
  }

  ul.how-to-use div p.super-min {
    padding-top: 15px;
    padding-left: 10px;
  }

  ul.how-to-use div p.super-max {
    padding-left: 20px;
  }

  div.terms#terms p.terms-sub {
    margin-right: 15px;
  }

  div.terms#terms p.subTitle.sub-words {
    margin-right: 15px;
  }
  div.terms#terms p.numbered.terms-sub {
    margin-right: 10px !important;
  }
  div.terms#terms p.established {
    margin-right: 10px !important;
  }

  div.terms#terms li.title:first-child {
    padding-top: 10px;
  }

  div#title-privacy p.title-privacy {
    margin: 0px;
    padding-right: 0px;
    padding-left: 5px;
  }

  div#title-privacy p.sub {
    margin-top: 15px;
    margin-left: 5px;
    margin-right: 15px !important;
  }

  div#title-privacy p.established {
    margin: 0px !important;
    margin-right: 10px !important;
  }
}

@media only screen and (max-width: 478px) {
  p#alert-dialog-description {
    padding: 0px;
  }
}

@media only screen and (max-width: 430px) {
  /* this is to apply the fixes to all Grids on iphone 11 
  and lower screens the negative margins and calc width causing UI issues */
  .css-mhc70k-MuiGrid-root {
    width: 100% !important;
    margin-left: 0px !important;
  }

  div.ant-popover-inner-content div.radio-group-btn {
    display: unset;
  }

  label.upload-video-btn,
  label.upload-video-btn:hover {
    font-size: 17px;
  }

  div.subTitleLabel {
    padding-top: 5px;
  }

  div.ant-popover-inner-content div label {
    padding: 0px;
  }

  div.video-setting-container.MuiGrid-item {
    margin-bottom: 0px;
  }

  h1.appTitle,
  h3.appTitle {
    font-size: 47px;
  }
  label.upload-video-btn,
  label.reuse-video-btn {
    font-size: 17px;
  }
}

@media only screen and (max-width: 418px) {
  img.how-to-use-image {
    margin-left: 0px;
    margin-top: 40px;
  }

  ul#how-to-use-mobile li.title p {
    width: 66%;
  }

  ul.how-to-use div p:first-child {
    padding-top: 35px !important;
  }

  div.company-container li.title.last-item {
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 414px) {
  label.upload-video-btn,
  label.reuse-video-btn {
    font-size: 17px;
  }
}

@media only screen and (max-width: 390px) {
  label.upload-video-btn,
  label.upload-video-btn:hover {
    font-size: 17px;
  }

  div.subTitleLabel {
    padding-top: 5px;
  }

  div.ant-popover-inner-content div.radio-group-btn {
    justify-content: center;
  }

  div.video-setting-container.MuiGrid-item {
    margin-bottom: 0px;
  }

  button.btn-sign,
  button.btn-sign,
  button.big-btn {
    font-size: 18px;
  }
  h1.appTitle,
  h3.appTitle {
    font-size: 47px;
  }
  label.upload-video-btn,
  label.reuse-video-btn {
    font-size: 17px;
  }

  div#header-modal-container div.modal-header-title {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 375px) {
  div.signature-canvas#signature-canvas {
    margin-top: 50px;
    margin: 0 1em 0;
  }

  a.file_link_download button.pdf-btn {
    font-size: 17px;
  }

  .box-settings > div.MuiCardContent-root {
    padding: 5px 10px 5px;
  }
}

@media only screen and (max-width: 287px) {
  .mosaic_settings div.input-form-control {
    width: 100%;
    margin: 0px;
  }
}

#alert-company-content {
  display: flex;
  justify-content: center;
  background: #f8f8f8;
  padding: 40px 40px 160px 40px;

  @media only screen and (max-width: 600px) {
    padding: 24px 16px 104px 16px;
  }
}

#alert-company-description {
  width: 100%;
}
